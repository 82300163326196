import styled from 'styled-components'
import Link from 'photon/src/components/Link'

export const LogoLink = styled(Link)`
  display: inline-block;
  margin: 0 auto 0 0;
`

export const LogoImg = styled.img`
  display: block;
  width: ${props => `calc(${props.size} * 0.7)` || '120px'};

  @media screen and (min-width: 900px) {
    width: ${props => props.size || '150px'};
  }
`
