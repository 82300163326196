import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  text-align: center;
  padding: 24px 0 43px;
`

export const Copyright = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`
