import { useEffect, useState } from 'react'
import wpapi from '../../../wpapi'

export const useSiteOptions = () => {

  const [siteOptions, setSiteOptions] = useState({})

  useEffect(() => {
    let __ok = true;

    const getSiteOptions = async () => {
      const _site_options = await wpapi.siteOptions.getSiteOptions()
      setSiteOptions(_site_options)
    }

    __ok && getSiteOptions()

    return () => __ok = false;
  }, [])

  return siteOptions
}
