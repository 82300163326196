import styled from 'styled-components'
import {useWidths} from 'photon'

export const LinkWrapper = styled.a.attrs(props => ({
  className: `photon-link`,
}))`
  color: ${props => (props.color || 'inherit')};
  text-decoration: ${props => (props.decoration || 'none')};
  cursor: pointer;
`
