import React, { useState } from 'react';
import logo from '../../../assets/westlooplogo.png';
import {
  MenuContainer,
  MenuWrapper,
  HamburgerToggle,
  HamburgerToggleLines,
  MenuItem
} from './styles'
import { useMenus } from '../../../../wpapi/hooks/menus'
import { arrEmpty } from '../../helpers'

function Menu({location}) {
  // state
  const menu = useMenus(location)
  const [isOpen, setIsOpen] = useState(false)

  if (!menu) return null

  return (<MenuContainer>
    <HamburgerToggle
      onClick={() => setIsOpen(!isOpen)}
    >
      <HamburgerToggleLines
        isOpen={isOpen}
      />
    </HamburgerToggle>
    <MenuWrapper 
      isOpen={isOpen}
    >
      {!arrEmpty(menu)
        && menu.map((menuItem, idx) => {
          return (<MenuItem
            key={menuItem.ID}
            href={menuItem.url}
          >
            {menuItem.title}
          </MenuItem>)
        })}
    </MenuWrapper>
  </MenuContainer>);
}

export default Menu;
