import React, { useEffect, useState } from 'react';
import {
  LogoLink,
  LogoImg,
} from './styles'
import { useSiteOptions } from '../../../../wpapi/hooks/site'

function Logo({size}) {
  // state
  const siteOptions = useSiteOptions()
  const [logoURL, setLogoURL] = useState(null)

  useEffect(() => {
    siteOptions?.logo && setLogoURL(siteOptions.logo)
  }, [siteOptions])

  return (logoURL
    && <LogoLink
      href={'/'}
    >
      <LogoImg size={'250px'} src={logoURL} alt="logo" />
    </LogoLink>);
}

export default Logo;
