import styled from 'styled-components'
import Box from '../Box'
import {useTheme} from 'photon'

export const ContainerWrapper = styled(Box).attrs(props => ({
  className: `photon-container`,
  mt: props.mt || '0',
  mb: props.mb || '0',
  ml: props.ml || 'auto',
  mr: props.mr || 'auto',
  widths: props.widths || useTheme('contentWidths')
}))`
  position: relative;
  ${props => true === props.flex &&
    `flex: 1;
    flex-direction: column;
    align-items: stretch;`
  }
`
