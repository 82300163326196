export default {
  'media': [
    "600px", // mobile
    "1024px", // tablet max
    "1300px", // laptop max
    "1500px", // desktop max
    "2000px", // xl max
  ],
  contentWidths: [
    "100%", // mobile
    "960px", // tablet max
    "1200px", // laptop max
    // "", // desktop max
    // "", // xl max
  ]
}
