/**
 * Photon
 */
import theme from './themes/photon'

export const useTheme = (key, def = '') => (theme[key] || def)

export const useWidths = (widths = []) => {
  const _medias = useTheme('media')
  return widths.map((width, idx) => {
    if (0 === idx) return `width: ${width};`
    return `@media screen and (min-width: ${_medias[idx]}) {
      width: ${width};
    }`
  })
}
