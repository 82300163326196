import React from 'react';
import './styles/main.scss'
import Header from './parts/Header'
import Footer from './parts/Footer'

import AppRouter from './router'


function App() {
  return (
    <>
      <Header />

      <AppRouter />

      {/* <Footer /> */}
    </>
  );
}

export default App;
