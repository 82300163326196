import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class SiteOptions {

  static getSiteOptions = async (args) => {
    try {
      const url = parseURL(getEndpoint('site', 'prt'), args)
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default SiteOptions
