import styled from 'styled-components'
import Link from 'photon/src/components/Link'
import Box from 'photon/src/components/Box'

export const MenuContainer = styled(Box)`
  align-self: flex-end;
  margin: 0 0 0 auto;
  overflow: hidden;
`

export const MenuWrapper = styled(Box).attrs(props => {
  isOpen: props.isOpen
})`
  position: absolute;
  background: #FFF;
  right: 0;
  left: 0;
  transition: ease-in-out 0.3s;
  padding-top: 3em;
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 899px) {
    ${props => props.isOpen
      ? `margin-left: 0%;
        width: 100%;`
      : `margin-left: 100%;
        width: 0;`}
  }

  @media screen and (min-width: 900px) {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
  }
`

export const HamburgerToggle = styled(Box).attrs(props => ({
  className: props.className + ' HamburgerToggle'
}))`
  grid-area: hamburger;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 20px;
  position: relative;
  user-select: none;
  visibility: visible;

  @media screen and (min-width: 900px) {
    display: none;
  }
`

export const HamburgerToggleLines = styled(Box).attrs(props => ({
  className: props.className + ' HamburgerToggleLines',
  isOpen: props.isOpen
}))`
  background: #333;
  display: block;
  height: 3px;
  width: 30px;
  position: relative;

  &:before {
    top: 8px;
  }

  &:after {
    top: -8px;
  }

  &:before,
  &:after {
    background: #333;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    transition: all .2s ease-out;
  }

  ${props => props.isOpen
    ? `background: transparent;

      &:before {
        transform: rotate(-45deg);
        top: 0;
      }
      &:after {
        transform: rotate(45deg);
        top: 0;
      }`
    : ``}
`

export const MenuItem = styled(Link)`
  font-size: 1.8em;
  margin: 0.5em 3em;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  color: #4c0a3e;
  border-bottom: 2px solid transparent;
  transition: 0.2s;

  &:hover {
    border-bottom-color: #8fb0c2;
  }

  @media screen and (min-width: 900px) {
    font-size: 1.3em;
    margin: 0 0 0 3em;
  }
`
