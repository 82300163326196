import React from 'react';
import Container from 'photon/src/components/Container'

import {
  FooterWrapper,
  Copyright
} from './styles'

function Footer(props) {
  return (<FooterWrapper>
    <Container className="header-inner">
      <Copyright>
        All Rights Reserved 2020
      </Copyright>
    </Container>
  </FooterWrapper>);
}

export default Footer
