import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {firebaseInit} from './firebase'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBijs4OBKGR_Eagvuy8XuaSrFOSAF2Lj1g",
  authDomain: "moxyassetmgmt.firebaseapp.com",
  databaseURL: "https://moxyassetmgmt.firebaseio.com",
  projectId: "moxyassetmgmt",
  storageBucket: "moxyassetmgmt.appspot.com",
  messagingSenderId: "426338573372",
  appId: "1:426338573372:web:aff3a4d98b8075035e3739",
  measurementId: "G-NWH8WDLSBS"
};

firebaseInit(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
