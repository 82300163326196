import styled from 'styled-components'
import {useWidths} from 'photon'

export const BoxWrapper = styled.div.attrs(props => ({
  className: `photon-box`,
  style: {...(props.style || {}), ...(props.flexBox || {})}
}))`
  display: ${props => props.flex ?
    `flex` :
      (props.inline) ?
      `inline-block` :
    `block`
  };

  ${props => props.pt && `padding-top: ${props.pt};`}
  ${props => props.pl && `padding-left: ${props.pl};`}
  ${props => props.pr && `padding-right: ${props.pr};`}
  ${props => props.pb && `padding-bottom: ${props.pb};`}

  ${props => props.mt && `margin-top: ${props.mt};`}
  ${props => props.ml && `margin-left: ${props.ml};`}
  ${props => props.mr && `margin-right: ${props.mr};`}
  ${props => props.mb && `margin-bottom: ${props.mb};`}

  ${props => props.widths && useWidths(props.widths)}

  box-sizing: border-box;
`
