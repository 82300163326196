import { useState, useEffect } from 'react'

// import {
//   Widths
// } from '../../styles'

// Hook
export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    // const currDevice = !isClient
    //   ? undefined
    //   : width <= Widths.maxMobile.replace('px', '')
    //     ? 'mobile'
    //     : width <= Widths.maxTablet.replace('px', '')
    //       ? 'tablet'
    //       : width <= Widths.maxLaptop.replace('px', '')
    //         ? 'laptop'
    //         : 'desktop'
    const windowSizes = {
      widths: {
        viewport: isClient
          ? document.body.clientWidth
          : undefined,
        viewportInclScrollbar: isClient
          ? window.innerWidth
          : undefined
      },
      heights: {
        viewport: isClient 
          ? document.body.clientHeight
          : undefined,
        viewportInclScrollbar: isClient
          ? window.innerHeight
          : undefined,
        header: isClient
          ? document.querySelector('header').clientHeight
          : undefined,
      }
      // mobileMax: Widths.maxMobile.replace('px', ''),
      // tabletMax: Widths.maxTablet.replace('px', ''),
      // laptopMax: Widths.maxLaptop.replace('px', ''),
      // currDevice: currDevice
    }
    // console.log('windowSizes', windowSizes)
    return windowSizes
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
