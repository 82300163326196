import {useEffect, useState} from 'react'
import wpapi from '../../../wpapi'

export const useMenus = (args) => {

  const [menus, setMenus] = useState([])
  const [params] = useState(args)

  useEffect(() => {
    let __ok = true;

    const getMenu = async () => {
      const _menus = await wpapi.menus.getByLocation(params)
      setMenus(_menus)
    }

    __ok && getMenu()

    return () => __ok = false;
  }, [params])

  return menus
}
