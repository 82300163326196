import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import loadable from '@loadable/component'

const _screens = './screens'
// Routes
const Page = loadable(() => import(`${_screens}/Page`))
const Home = loadable(() => import(`${_screens}/Home`))

export default function AppRouter() {
  return (
    <Router>
      <Switch>

        {/* Custom WP Pages */}
        <Route path="/:slug">
          <Page />
        </Route>

        {/* Home Page */}
        <Route exact path="/">
          <Home />
        </Route>

        {/* 404 Page */}
        {
          // <Route path="/*">
          //   <NoMatch />
          // </Route>
        }

      </Switch>
    </Router>
  );
}
