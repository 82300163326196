import SiteOptions from './models/SiteOptions'
import Posts from './models/Posts'
import Pages from './models/Pages'
import Portfolio from './models/Portfolio'
import Menus from './models/Menus'
import Media from './models/Media'
import Forms from './models/Forms'

export const wpapiConfig = {
  protocol: 'https',
  domain: 'wordpress.photon.software/moxy-asset-management',
  // protocol: 'http',
  // domain: 'api.prt.test',
  // domain: 'dops.local',
};

export const base = `${wpapiConfig.protocol}://${wpapiConfig.domain}/wp-json`;

export const namespaces = {
  wp: '/wp/v2',
  prt: '/prt/v1',
};

const wpapi = {
  base,
  ns: namespaces,
  // models
  siteOptions: SiteOptions,
  posts: Posts,
  pages: Pages,
  portfolio: Portfolio,
  menus: Menus,
  media: Media,
  forms: Forms,
}

export default wpapi
