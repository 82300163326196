/**
 * Header
 */
import React from 'react';
// import 'spectra'

import {
  HeaderWrapper,
  HeaderInner,
} from './styles'

import Logo from '../../lib/components/Logo'
import Menu from '../../lib/components/Menu'

function Header(props) {
  return (<HeaderWrapper>
    <HeaderInner className="header-inner">
      <Logo />
      <Menu location={'primary'} />
    </HeaderInner>
  </HeaderWrapper>);
}

export default Header
