export const HIDE_ON_DESKTOP = "hide-on-laptop";

export const WIDTHS = {
  MOBILE: {
    MAX: '',
    MIN: '',
  },
  TABLET: {
    MAX: '',
    MIN: '',
  },
  DESKTOP: {
    MAX: '',
    MIN: '',
  }
};

export const EMAIL_REGEX_VALIDATION = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/

export const NON_CORPORATE_EMAILS = ['@gmail', '@yahoo', '@aol', '@explorer']
