import styled from 'styled-components'
import Container from 'photon/src/components/Container'

export const HeaderWrapper = styled.header`
  width: 100%;
  padding: 26px 15px;
  box-sizing: border-box;
  z-index: 99;
  background-color: #FFF;

  // @media screen and (min-width: 900px) {
    position: absolute;
    top: 0;
    left: 0;
  // }
`

export const HeaderInner = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`
