import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class Pages {

  static submit = async (formData) => {
    try {
      const url = parseURL(getEndpoint('forms', 'prt'), {...formData})
      const response = await axios.post(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Pages
